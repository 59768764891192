import React from "react";
import Helmet from "react-helmet";
import { Button, Grid } from "@mui/material";
import { MonetizationOn } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import { makeTabTitle } from "../../utils";


export default function Home() {
    const navigate = useNavigate();
    const handleStartSimulationClick = (e: React.MouseEvent<HTMLElement>) => navigate("/simulator");

    return (
        <>
            <Helmet title={makeTabTitle("Home")} />
            <Grid container sx={{ marginTop: "4rem" }}>
                <Grid item xs={12} sx={{ textAlign: "center" }}>
                    <h2>Bem vindo ao simulador!</h2>
                    <h4>
                        Aqui você poderá simular a compra do carro dos seus sonhos com base na tabela <a
                            href="https://veiculos.fipe.org.br/"
                            target="_blank"
                            rel="noreferrer"
                            style={{ textDecoration: "none", color: "#651fff" }}
                        >
                            FIPE
                        </a>!
                    </h4>
                </Grid>
                <Grid item xs={12} sx={{ textAlign: "center", marginTop: "4rem" }}>
                    <Button
                        startIcon={<MonetizationOn />}
                        variant="contained"
                        sx={{ color: "white" }}
                        onClick={handleStartSimulationClick}
                    >
                        Começar Simulação
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}
