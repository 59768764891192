/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from "react";
import { DirectionsCar, Menu as MenuIcon } from "@mui/icons-material";
import { AppBar, Box, Container, Divider, IconButton, Menu, MenuItem, Toolbar, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";


export default function Header() {
    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
    const navigate = useNavigate();
    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => setAnchorElNav(event.currentTarget);
    const handleCloseNavMenu = (event: any, path?: string) => {
        if (path) navigate(path);
        setAnchorElNav(null);
    };

    return (
        <AppBar position="static" sx={{ color: "white" }}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    {/* Responsive Headers starts */}
                    <DirectionsCar sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        Compra Carro Sim
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={!!anchorElNav}
                            onClose={(e, reason) => handleCloseNavMenu(e)}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            <MenuItem>
                                <a
                                    target="_blank"
                                    href="https://www.amazon.com.br/b?_encoding=UTF8&tag=jgtvares-20&linkCode=ur2&linkId=47aa1af655014783644bfa63eed8ef3c&camp=1789&creative=9325&node=18914209011"
                                    style={{ textDecoration: "none", color: "inherit" }}
                                >
                                    Automotivos mais vendidos na Amazon
                                </a>
                            </MenuItem>
                        </Menu>
                    </Box>
                    {/* Responsive Header ends */}
                    <DirectionsCar sx={{ display: { xs: 'none', md: 'none' }, mr: 1 }} />
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        Compra Carro Sim
                    </Typography>
                    <Divider orientation="vertical" variant="middle" sx={{ borderColor: "#7c4dff", display: { xs: "none", md: "flex" } }} flexItem />
                    <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" }, marginLeft: "1rem" }}>
                        <a
                            target="_blank"
                            href="https://www.amazon.com.br/b?_encoding=UTF8&tag=jgtvares-20&linkCode=ur2&linkId=47aa1af655014783644bfa63eed8ef3c&camp=1789&creative=9325&node=18914209011"
                            style={{ textDecoration: "none", color: "inherit" }}
                        >
                            Automotivos mais vendidos na Amazon
                        </a>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
