import React, { createRef, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Button, CircularProgress, Grid, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { ArrowDownwardOutlined, ArrowForwardOutlined, ElectricCar } from "@mui/icons-material";

import { isMobile, makeTabTitle, scrollToDiv } from "../../utils";
import { useBrands, useModels, useYears } from "../../hooks";
import { CarDetails, FinancingDetails } from "../../types/cars.type";
import * as FipeApiService from "../../services/fipeApi.service";
import GenericDialog from "../../components/Dialogs/Generic";
import FinancingForm from "../../components/Forms/Financing";


export default function Simulator() {
    const [selectedBrand, setSelectedBrand] = useState("");
    const [selectedModel, setSelectedModel] = useState("");
    const [selectedYear, setSelectedYear] = useState("");
    const [loadingCarDetails, setLoadingCarDetails] = useState(false);
    const [showFinancingForm, setShowFinancingForm] = useState(false);
    const [carDetails, setCarDetails] = useState<CarDetails>();
    const [financingDetails, setFinancingDetails] = useState<FinancingDetails>();
    const pageEndRef: React.RefObject<HTMLDivElement> = createRef();

    const { brands, loadingBrands } = useBrands();
    const { loadingModels, models } = useModels(selectedBrand);
    const { years, loadingYears } = useYears(selectedBrand, selectedModel);

    const handleBrandSelection = (e: SelectChangeEvent<string>) => {
        setSelectedBrand(e.target.value);
        setCarDetails(undefined);
        setFinancingDetails(undefined);
        setSelectedModel("");
        setSelectedYear("");
    };
    const handleModelSelection = (e: SelectChangeEvent<string>) => {
        setSelectedModel(e.target.value);
        setCarDetails(undefined);
        setFinancingDetails(undefined);
        setSelectedYear("");
    };
    const handleYearSelection = (e: SelectChangeEvent<string>) => {
        setSelectedYear(e.target.value);
        setCarDetails(undefined);
        setFinancingDetails(undefined);
    };
    const toggleFinancingForm = () => setShowFinancingForm(!showFinancingForm);
    const handleSimulateClick = async (e: React.MouseEvent<HTMLElement>) => {
        setLoadingCarDetails(true);
        FipeApiService.GetCarDetails(selectedBrand, selectedModel, selectedYear).then((data) => {
            setCarDetails(data);
        }).finally(() => setLoadingCarDetails(false));
    };

    useEffect(() => {
        if ((!!carDetails || !!financingDetails) && !showFinancingForm) scrollToDiv(pageEndRef);
    }, [carDetails, financingDetails, pageEndRef, showFinancingForm]);

    return (
        <>
            <Helmet title={makeTabTitle("Simulador")} />
            <Grid container sx={{ marginTop: "4rem", display: "flex", gap: "2rem", justifyContent: "center", alignItems: "center" }}>
                <Grid item xs={isMobile() ? 10 : 2}>
                    <h4>Escolha a marca:</h4>
                    <Select
                        value={selectedBrand}
                        onChange={handleBrandSelection}
                        disabled={loadingBrands}
                        size="small"
                        fullWidth
                    >
                        {brands?.map((brand, i) => (
                            <MenuItem key={i} value={brand.codigo}>
                                {brand.nome}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid item xs={isMobile() ? 10 : 0.2}>
                    {isMobile() ?
                        <ArrowDownwardOutlined color={selectedBrand ? "primary" : "disabled"} />
                        :
                        <ArrowForwardOutlined color={selectedBrand ? "primary" : "disabled"} />
                    }
                </Grid>
                <Grid item xs={isMobile() ? 10 : 2}>
                    <h4 style={{ color: loadingModels || !selectedBrand ? "gray" : "inherit" }}>Escolha o modelo:</h4>
                    <Select
                        value={selectedModel}
                        onChange={handleModelSelection}
                        disabled={loadingModels || !selectedBrand}
                        size="small"
                        fullWidth
                    >
                        {models?.modelos.map((model, i) => (
                            <MenuItem key={i} value={model.codigo}>
                                {model.nome}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid item xs={isMobile() ? 10 : 0.2}>
                    {isMobile() ?
                        <ArrowDownwardOutlined color={selectedModel ? "primary" : "disabled"} />
                        :
                        <ArrowForwardOutlined color={selectedModel ? "primary" : "disabled"} />
                    }
                </Grid>
                <Grid item xs={isMobile() ? 10 : 2}>
                    <h4 style={{ color: loadingYears || !selectedBrand || !selectedModel ? "gray" : "inherit" }}>Escolha o ano:</h4>
                    <Select
                        value={selectedYear}
                        onChange={handleYearSelection}
                        disabled={loadingYears || !selectedBrand || !selectedModel}
                        size="small"
                        fullWidth
                    >
                        {years?.map((year, i) => (
                            <MenuItem key={i} value={year.codigo}>
                                {year.nome.replace("32000", "Zero KM")}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid item xs={isMobile() ? 10 : 0.2}>
                    {isMobile() ?
                        <ArrowDownwardOutlined color={selectedYear ? "primary" : "disabled"} />
                        :
                        <ArrowForwardOutlined color={selectedYear ? "primary" : "disabled"} />
                    }
                </Grid>
                <Grid item xs={isMobile() ? 10 : 2}>
                    <h4 style={{ color: !selectedBrand || !selectedModel || !selectedYear ? "gray" : "inherit" }}>Clique pra iniciar</h4>
                    <Button
                        variant="contained"
                        startIcon={loadingCarDetails ? <CircularProgress size={24} /> : <ElectricCar />}
                        sx={{ color: "#fff" }}
                        onClick={handleSimulateClick}
                        disabled={!selectedBrand || !selectedModel || !selectedYear || loadingCarDetails}
                    >
                        Carregar
                    </Button>
                </Grid>
            </Grid>
            {!!carDetails &&
                <Grid container sx={{ marginTop: "4rem", justifyContent: "center", display: "flex" }}>
                    <GenericDialog
                        onClose={toggleFinancingForm}
                        open={showFinancingForm}
                        title="Simular Financiamento"
                    >
                        <FinancingForm
                            carDetails={carDetails}
                            setFinancingDetails={setFinancingDetails}
                            onClose={toggleFinancingForm}
                        />
                    </GenericDialog>
                    <Grid item xs={isMobile() ? 10 : 4}>
                        <h3>Detalhes do carro:</h3>
                        <p style={{ color: "#6200ea" }}><strong>Marca</strong>: <span>{carDetails.Marca}</span></p>
                        <p style={{ color: "#6200ea" }}><strong>Modelo</strong>: <span>{carDetails.Modelo}</span></p>
                        <p style={{ color: "#6200ea" }}><strong>Ano</strong>: <span>{carDetails.AnoModelo}</span></p>
                        <p style={{ color: "#6200ea" }}><strong>Combustível</strong>: <span>{carDetails.Combustivel}</span></p>
                        <p style={{ color: "#6200ea" }}><strong>Código FIPE</strong>: <span>{carDetails.CodigoFipe}</span></p>
                        <p style={{ color: "#6200ea" }}><strong>Mês de Referência</strong>: <span>{carDetails.MesReferencia}</span></p>
                        <p style={{ color: "#6200ea" }}><strong>Valor na tabela FIPE</strong>: <span>{carDetails.Valor}</span></p>
                    </Grid>
                    <Grid item xs={isMobile() ? 10 : 4} sx={{ marginTop: isMobile() ? "0.5rem" : undefined }}>
                        <Button
                            variant="contained"
                            sx={{ color: "#fff" }}
                            onClick={toggleFinancingForm}
                        >
                            Simular Financiamento
                        </Button>
                    </Grid>
                    {!!financingDetails &&
                        <Grid item xs={isMobile() ? 10 : 8} sx={{ marginTop: "2rem" }}>
                            <h3>Detalhes do financiamento:</h3>
                            <p style={{ color: "#6200ea" }}><strong>Valor a ser financiado</strong>: <span>{Number(financingDetails.ValueToFinance).toLocaleString("pt-BR", { style: "currency", currency: "BRL", minimumFractionDigits: 2 })}</span></p>
                            <p style={{ color: "#6200ea" }}><strong>Juros mensais</strong>: <span>{financingDetails.InterestPercentage}%</span></p>
                            <p style={{ color: "#6200ea" }}><strong>Número de parcelas</strong>: <span>{financingDetails.NumberOfInstallments}</span></p>
                            <p style={{ color: "#6200ea" }}><strong>Valor de cada parcela</strong>: <span>{Number(financingDetails.ValuePerInstallment).toLocaleString("pt-BR", { style: "currency", currency: "BRL", minimumFractionDigits: 2 })}</span></p>
                            <p style={{ color: "#6200ea" }}><strong>Valor que será pago só de juros</strong>: <span>{Number(financingDetails.TotalValueOfInterest).toLocaleString("pt-BR", { style: "currency", currency: "BRL", minimumFractionDigits: 2 })}</span></p>
                            <p style={{ color: "#6200ea" }}><strong>Valor final do financiamento (+ juros)</strong>: <span>{Number(financingDetails.FinalFinancingValue).toLocaleString("pt-BR", { style: "currency", currency: "BRL", minimumFractionDigits: 2 })}</span></p>
                        </Grid>
                    }
                    <div ref={pageEndRef} />
                </Grid>
            }
        </>
    );
}
