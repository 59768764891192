import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";


type GenericDialogProps = {
    children: React.JSX.Element;
    open: boolean;
    title: string;
    onClose: () => void;
    useConfirmAndCancelButtons?: boolean;
    actions?: React.JSX.Element;
    onConfirm?: (arg?: any) => Promise<any>;
    disableConfirm?: boolean;
}

export default function GenericDialog({
    children,
    onClose,
    open,
    title,
    actions,
    disableConfirm,
    onConfirm,
    useConfirmAndCancelButtons,
}: GenericDialogProps) {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
        >
            <DialogTitle sx={{ textAlign: "center", fontWeight: "bold", color: "#6200ea" }}>{title}</DialogTitle>
            <DialogContent>{children}</DialogContent>
            <DialogActions>
                {useConfirmAndCancelButtons &&
                    <>
                        <Button onClick={onClose} color="error" variant="outlined" autoFocus>Cancelar</Button>
                        <Button disabled={disableConfirm} onClick={onConfirm} color="primary" variant="outlined">Confirmar</Button>
                    </>
                }
                {actions && !useConfirmAndCancelButtons && <>{actions}</>}
            </DialogActions>
        </Dialog>
    );
}
