import React from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";

import { defaultTheme } from "../../themes";
import AppRoutes from "../../routes";
import Header from "./Header";
import { BrowserRouter as Router } from "react-router-dom";
import Footer from "./Footer";


export default function Layout() {
    return (
        <ThemeProvider theme={defaultTheme}>
            <CssBaseline />
            <Router>
                <Header />
                <AppRoutes />
                <div style={{ clear: "both", height: "80px" }}></div>
                <Footer />
            </Router>
        </ThemeProvider>
    );
}
