import { RefObject } from "react";


export const makeTabTitle = (pageName: string) => `Compra Carro Sim | ${pageName}`;
export const BRLStringToNumber = (value: string) => {
    const brlValue = value.replace("R$", "").trim();
    const [initial, decimals] = brlValue.split(",");
    const formattedInitial = initial.replaceAll(".", "");
    const joined = [formattedInitial, decimals].join(".");
    return Number(joined);
};

export const isMobile = () => window.screen.width <= 768;
export const scrollToDiv = (ref: RefObject<HTMLDivElement>) => ref.current?.scrollIntoView({ behavior: "smooth" });
