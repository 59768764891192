import { Alert, Button, FormControl, InputAdornment, InputLabel, MenuItem, Select, SelectChangeEvent, Snackbar, TextField } from "@mui/material";
import React, { useState } from "react";
import { NumberFormatBase, NumberFormatValues } from "react-number-format";
import { MonetizationOn } from "@mui/icons-material";

import { CarDetails, FinancingDetails } from "../../../types/cars.type";
import { BRLStringToNumber, isMobile } from "../../../utils";
import { bcbInterestList } from "../../../config";
import { BankInterestDetails } from "../../../types/bcb.type";


type FinancingFormProps = {
    carDetails: CarDetails;
    setFinancingDetails: (details: FinancingDetails) => void;
    onClose?: () => void;
};

export default function FinancingForm({ carDetails, setFinancingDetails, onClose }: FinancingFormProps) {
    const [numberOfInstallments, setNumberOfInstallments] = useState("");
    const [interestPercentage, setInterestPercentage] = useState("");
    const [entry, setEntry] = useState("");
    const [selectedBank, setSelectedBank] = useState<BankInterestDetails>();
    const [isSelectingBank, setIsSelectingBank] = useState(false);
    const [showBankAlert, setShowBankAlert] = useState(false);
    const style: React.CSSProperties = {
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        gap: "3rem",
        marginTop: "1rem",
    };

    const handleNumberOfInstallmentsChange = (values: NumberFormatValues) => setNumberOfInstallments(values.value);
    const handleInterestChange = (values: NumberFormatValues) => setInterestPercentage((Number(values.value) / 100).toFixed(2));
    const handleEntryChange = (values: NumberFormatValues) => setEntry((Number(values.value) / 100).toFixed(2));
    const handleBankSelection = (e: SelectChangeEvent<string>) => {
        const bank = bcbInterestList.find((b) => b.InstituicaoFinanceira === e.target.value)
        if (bank) {
            setSelectedBank(bank);
            setInterestPercentage(BRLStringToNumber(bank.TaxaJurosAoMes).toFixed(2));
        }
    };
    const toggleSelectBank = (e: React.MouseEvent<HTMLElement>) => {
        setIsSelectingBank(!isSelectingBank);
        setShowBankAlert(!isSelectingBank);
    };
    const hideBankAlert = () => setShowBankAlert(false);
    const formatPrice = (value: string) => {
        if (!value) return '';
        const formatted = new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
            minimumFractionDigits: 2
        }).format(parseFloat(value) / 100);
        return formatted;
    };
    const formatPercent = (value: string) => {
        if (!value) return '';
        const formatted = new Intl.NumberFormat("pt-BR", {
            style: "decimal",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(Number(value) / 100);
        return formatted;
    };
    const handleSubmit = () => {
        const formattedCarValue = BRLStringToNumber(carDetails.Valor);
        const valueToFinance = Number(formattedCarValue) - Number(entry);
        const decimalInterest = Number(interestPercentage) / 100;
        const x = (1 + decimalInterest)**Number(numberOfInstallments);
        const installmentValue = valueToFinance * ((x * decimalInterest) / (x - 1));
        const finalFinancingValue = installmentValue * Number(numberOfInstallments);
        const financingDetails: FinancingDetails = {
            ValueToFinance: valueToFinance.toFixed(2),
            InterestPercentage: interestPercentage,
            NumberOfInstallments: numberOfInstallments,
            ValuePerInstallment: installmentValue.toFixed(2),
            FinalFinancingValue: finalFinancingValue.toFixed(2),
            TotalValueOfInterest: (finalFinancingValue - valueToFinance).toFixed(2),
        };
        setFinancingDetails(financingDetails);
        onClose?.();
    };

    return (
        <div style={style}>
            <Snackbar
                open={showBankAlert}
                autoHideDuration={10000}
                onClose={hideBankAlert}
                anchorOrigin={{
                    horizontal: "center",
                    vertical: "top",
                }}
            >
                <Alert variant="filled" severity="info">
                    A lista de bancos e taxas de juros é baseada na <a
                        href="https://www.bcb.gov.br/estatisticas/reporttxjuros/?codigoSegmento=1&codigoModalidade=401101&historicotaxajurosdiario_atual_page=1&tipoModalidade=D&InicioPeriodo=2024-01-25"
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: "#fff", fontWeight: "bold" }}
                    >
                        tabela fornecida pelo Banco Central do Brasil
                    </a>. Caso o banco que você esteja considerando não pratique a taxa de juros fornecida pelo BCB, exija a diminuição da taxa na agência!
                </Alert>
            </Snackbar>
            <FormControl>
                <NumberFormatBase
                    label="Número de parcelas"
                    placeholder="0"
                    inputProps={{ inputMode: "numeric" }}
                    value={numberOfInstallments}
                    onValueChange={handleNumberOfInstallmentsChange}
                    customInput={TextField}
                />
            </FormControl>
            <FormControl>
                {isSelectingBank && !!bcbInterestList?.length ?
                    <>
                        <InputLabel id="bank-select">Selecione um banco</InputLabel>
                        <Select
                            label="Selecione um banco"
                            placeholder="Selecione um banco"
                            labelId="bank-select"
                            value={selectedBank?.InstituicaoFinanceira || ""}
                            onChange={handleBankSelection}
                            renderValue={(value) => <span style={{ display: "flex" }}>{selectedBank?.InstituicaoFinanceira} - {selectedBank?.TaxaJurosAoMes}%</span>}
                            endAdornment={
                                <InputAdornment position="end">
                                    <Button
                                        onClick={toggleSelectBank}
                                        sx={{
                                            marginRight: "0.5rem",
                                            fontSize: isMobile() ? "0.5rem" : undefined,
                                        }}
                                    >
                                        Digitar manualmente
                                    </Button>
                                </InputAdornment>
                            }
                        >
                            {bcbInterestList.map((bank, i) => (
                                <MenuItem key={i} value={bank.InstituicaoFinanceira}>
                                    {bank.InstituicaoFinanceira} - {bank.TaxaJurosAoMes}%
                                </MenuItem>
                            ))}
                        </Select>
                    </>
                    :
                    <NumberFormatBase
                        label="Juros (%)"
                        format={formatPercent}
                        placeholder="0,00"
                        value={interestPercentage}
                        onValueChange={handleInterestChange}
                        customInput={TextField}
                        inputProps={{ inputMode: "numeric" }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Button
                                        disabled={!bcbInterestList?.length}
                                        onClick={toggleSelectBank}
                                        sx={{ fontSize: isMobile() ? "0.5rem" : undefined }}
                                    >
                                        Selecionar banco
                                    </Button>
                                </InputAdornment>
                            ),
                        }}
                    />
                }
            </FormControl>
            <FormControl>
                <NumberFormatBase
                    label="Valor de entrada"
                    format={formatPrice}
                    placeholder="R$ 0,00"
                    value={entry}
                    onValueChange={handleEntryChange}
                    customInput={TextField}
                    inputProps={{ inputMode: "numeric" }}
                />
            </FormControl>
            <FormControl>
                <Button
                    variant="contained"
                    startIcon={<MonetizationOn />}
                    sx={{ color: "#fff" }}
                    disabled={!numberOfInstallments || (!interestPercentage && !selectedBank)}
                    onClick={handleSubmit}
                >
                    Simular
                </Button>
            </FormControl>
        </div>
    );
}
