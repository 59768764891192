import { createTheme } from "@mui/material";
import { deepPurple } from "@mui/material/colors";


export const defaultTheme = createTheme({
    palette: {
        primary: {
            main: deepPurple.A400,
            light: deepPurple.A100,
            contrastText: deepPurple.A200,
            dark: deepPurple.A700,
        },
        background: {
            default: deepPurple[50],
        },
    }
});