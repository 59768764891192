import { Navigate, Route, Routes } from "react-router-dom";

import Home from "../pages/Home";
import Simulator from "../pages/Simulator";


export default function AppRoutes() {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/simulator" element={<Simulator />} />
            <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
    );
}