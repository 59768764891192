import { FipeApiClient } from "../clients/fipeApi.client";
import { CarDetails, FipeApiModel } from "../types/cars.type";


export async function GetBrands(): Promise<FipeApiModel[]> {
    const res = await FipeApiClient.get("/carros/marcas");
    return res.data;
}

export async function GetModelsByBrand(brandCode: string): Promise<{ anos: FipeApiModel[]; modelos: FipeApiModel[] }> {
    const res = await FipeApiClient.get(`/carros/marcas/${brandCode}/modelos`);
    return res.data;
}

export async function GetModelYears(brandCode: string, modelCode: string): Promise<FipeApiModel[]> {
    const res = await FipeApiClient.get(`/carros/marcas/${brandCode}/modelos/${modelCode}/anos`);
    return res.data;
}

export async function GetCarDetails(brandCode: string, modelCode: string, yearCode: string): Promise<CarDetails> {
    const res = await FipeApiClient.get(`/carros/marcas/${brandCode}/modelos/${modelCode}/anos/${yearCode}`);
    return res.data;
}
