import React, { CSSProperties, useState } from "react";
import { Grid, Tooltip } from "@mui/material";

import { appVersion } from "../../../config";
import { ReactComponent as LinkedinIcon } from "../../../assets/svg/linkedin.svg";
import { ReactComponent as GithubIcon } from "../../../assets/svg/github.svg";
import { isMobile } from "../../../utils";


export default function Footer() {
    const [isHoveringMyName, setIsHoveringMyName] = useState(false);
    const style: CSSProperties = {
        position: "fixed",
        width: "100%",
        bottom: 0,
        backgroundColor: "#6200ea",
        color: "#ede7f6",
        padding: "0.8rem",
        fontWeight: "bold",
        fontSize: isMobile() ? "0.8rem" : undefined,
    };

    return (
        <footer style={style}>
            <Grid container sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "1rem" }}>
                <Grid item xs={isMobile() ? 3 : 2}>
                    <span>Versão: {appVersion}</span>
                </Grid>
                <Grid item xs={isMobile() ? 5 : 2}>
                    <span>
                        Feito por <Tooltip arrow title="Acesse meu site pessoal">
                            <a
                                href="https://jgtvares.vercel.app"
                                target="_blank"
                                rel="noreferrer"
                                style={{ textDecoration: isMobile() ? undefined : "none", color: isHoveringMyName ? "black" : "inherit", }}
                                onMouseOver={(e) => setIsHoveringMyName(true)}
                                onMouseOut={(e) => setIsHoveringMyName(false)}
                            >
                                João Tavares
                            </a>
                        </Tooltip>
                    </span>
                </Grid>
                <Grid item xs={isMobile() ? 1.5 : 2} sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "0.8rem" }}>
                    <Tooltip arrow title="Acesse meu LinkedIn">
                        <a
                            href="https://www.linkedin.com/in/jgtvares/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <LinkedinIcon height={isMobile() ? 16 : 24} />
                        </a>
                    </Tooltip>
                    <Tooltip arrow title="Acesse meu Github">
                        <a
                            href="https://github.com/jgtvares"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <GithubIcon height={isMobile() ? 16 : 24} width={isMobile() ? 16 : 24} />
                        </a>
                    </Tooltip>
                </Grid>
            </Grid>
        </footer>
    );
}
