import { BankInterestDetails } from "../types/bcb.type";
import pkg from "../../package.json";


export const fipeApiUrl = process.env.REACT_APP_FIPE_API_URL;
export const appVersion = pkg.version;
export const bcbInterestList = (() => {
    let value: BankInterestDetails[] = [];
    try {
        const parsed = JSON.parse(process.env.REACT_APP_BCB_INTEREST_LIST!) as BankInterestDetails[];
        if (typeof parsed === "string") value = JSON.parse(parsed)
        else value = parsed;
    } catch (err) {
        console.error("Could not parse 'REACT_APP_BCP_INTEREST_LIST', setting an empty array as value!");
    }

    return value;
})();
