import { useQuery } from "@tanstack/react-query";

import * as FipeApiService from "../services/fipeApi.service";


export const useBrands = () => {
    const { data, isLoading, fetchStatus } = useQuery({
        queryKey: ['brands'],
        queryFn: () => FipeApiService.GetBrands(),
        enabled: true,
        notifyOnChangeProps: ['data'],
    });

    return {
        brands: data,
        loadingBrands: isLoading && fetchStatus !== "idle",
    };
};

export const useModels = (brandCode: string) => {
    const { data, isLoading, fetchStatus } = useQuery({
        queryKey: ['models', brandCode],
        queryFn: () => FipeApiService.GetModelsByBrand(brandCode),
        enabled: !!brandCode,
        notifyOnChangeProps: ['data'],
    });

    return {
        models: data,
        loadingModels: isLoading && fetchStatus !== "idle",
    };
};

export const useYears = (brandCode: string, modelCode: string) => {
    const { data, isLoading, fetchStatus } = useQuery({
        queryKey: ['years', modelCode],
        queryFn: () => FipeApiService.GetModelYears(brandCode, modelCode),
        enabled: !!brandCode && !!modelCode,
        notifyOnChangeProps: ['data'],
    });

    return {
        years: data,
        loadingYears: isLoading && fetchStatus !== "idle",
    };
};
